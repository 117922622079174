/* because Aggregator is so simple, we don't namespace the css by product */
/* instead we've split product specific styles to dedicated files, and then just redefined common classes */
.agg-navbar {
    background-color: #1c2d42;
}
.agg-banner-container {
    background-image: url(/static/img/transparent_mesh_2.png);
    background-color: #1c2d42;
}
.agg-banner {
    background-image:url(/static/img/header_BG_citizen_space.png);
}
