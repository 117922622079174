/* Aggregator css is short, no LESS compile is required */
/* ---------------------------------------------------- */

:root {
    --fade-in: fadeIn 0.5s forwards;
}
@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

/**
    Header
 */
header .navbar-header a {
    font-size: 0;
}
header .navbar-right a {
    color: white;
    margin-right: 15px;
}

#global-wrapper {
    background-color: #eeefeb;
    background-image: url('../dlb-public-ui/img/bg_textures/greige_mesh_lighter.png');
}

.text-muted a {
    color: inherit;
    text-decoration: none;
}

/**
    Footer
 */
.agg-footer-copyright span, agg-footer-colophon span {
    vertical-align: middle;
}
.agg-footer-copyright span.icomoon-delib {
    font-size: 18px;
}
.agg-footer-colophon span a {
    text-decoration: underline;
}

/* Results count and pages count */
.agg-search-results-page-count {
    display: inline-block;
}
@media screen and (min-width: 768px) {
    .agg-search-results-page-count {
        float: none;
    }
}
@media screen and (max-width: 767px) {
    .agg-search-results-page-count {
        float: right;
    }
}

/* sort widget */
@media screen and (min-width: 768px) {
    #agg-sort-order-box {
        text-align: right;
    }
}
@media screen and (max-width: 767px) {
    #agg-sort-order-box {
        text-align: left;
        margin-top: 10px;
    }
}

/* Pagination widget */
.agg-pagination-widget, .agg-pagination-widget a {
    color: #333;
}
@media screen and (min-width: 768px) {
    .agg-pagination-widget {
        margin-top: 0;
        float: right;
    }
}
@media screen and (max-width: 767px) {
    .agg-pagination-widget {
        margin-top: 10px;
        float: none;
    }
    .agg-pagination-widget-next {
        float: right;
    }
}

/* Consultation cards */
@media screen and (min-width: 768px) {
    .agg-date-delta {
      text-align:right;
    }
}
@media screen and (max-width: 767px) {
    .agg-date-delta {
      text-align:left;
    }
}


/* Global navbar */
.agg-navbar {
    /* knock off some bootstrap styles */
    border-radius: 0;
    border-color: transparent;
    margin-bottom: 0;
}

/* banner (hero) */
/* assumed to be applied inside a dss-rhino as of Jan 2017 */
.agg-banner {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.agg-searching {
    margin-top: 60px;
}

.agg-results-heading {
    font-size: inherit;
    line-height: inherit;
}

/* Footer */
.agg-footer {
    font-size: 16px;
}
@media screen and (min-width: 768px) {
    .agg-footer-colophon {
        float: none;
    }
    .agg-footer-copyright {
        float: right;
    }
}
@media screen and (max-width: 767px) {
    .agg-footer-colophon {
        float: none;
    }
    .agg-footer-copyright {
        float: none;
    }
}

@media screen and (max-width: 767px) {
    .agg-splash-page-icon {
        margin-bottom: 1em;
    }
}

/*
Skeleton Loading
*/
.agg-skeleton {
    opacity: 0.7;
}
/*.agg-skeleton-activities {
    width: 0px;
    animation: fullView 0.3s forwards cubic-bezier(0.250, 0.460, 0.450, 0.940);
}
@keyframes fullView {
    100% {
      width: 100%;
    }
}*/

/*
#activities {
    opacity: 0.7;
    animation: var(--fade-in);
}
.agg-results-loaded-heading {
    opacity: 0;
    animation: var(--fade-in);
}
*/

.agg-skeleton {
    --skeleton-on-light: linear-gradient(to right, rgba(0,0,0,0.05) 4%, rgba(0,0,0,0.1), rgba(0,0,0,0.05) 36%);
    --skeleton-on-dark: linear-gradient(to right, rgba(255,255,255,0.07) 4%, rgba(255,255,255,0.12), rgba(255,255,255,0.07) 36%);
}

.agg-skeleton div, .agg-skeleton p, .agg-skeleton h3 {
    border-radius: 4px;
}

.agg-skeleton-label {
    display: inline-block;
    width: 200px;
    max-width: 100%;
    margin-bottom: 5px;
}
.agg-skeleton-label-keyword {
    width: 130px;
}
.agg-skeleton-label-org {
    width: 90px;
}
.agg-skeleton-button {
    border: 1px solid rgba(0,0,0,0.07);
    border-radius: 2px;
}

.agg-skeleton-input {
    display: inline-block;
    border: 1px solid rgba(0,0,0,0.07);
}

.agg-skeleton-activity {
    background-color: #fcfcfc;
    border-left-color: #147cb479;
}
.agg-skeleton-precis {
    margin-bottom: 11px;
}
.agg-skeleton-precis p {
    margin-bottom: 0;
}

.agg-skeleton-animated {
    animation : shimmer 5s infinite linear;
    background: var(--skeleton-on-light);
    background-size: 1000px 100%;
}
.agg-skeleton-animated.agg-skeleton-input, .agg-skeleton-animated.agg-skeleton-label {
    background: var(--skeleton-on-dark);
    background-size: 1000px 100%;
}
.agg-skeleton-animated.agg-skeleton-button {
    background: var(--skeleton-on-dark);
    background-size: 1000px 100%;
}

@keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
}

/*
    Other styles moved from inline
 */
.agg-search-button-container {
    margin-top:27px;
}
.agg-search-button-container button {
    width:100%;
    white-space: nowrap;
}
.agg-search-inputs .dss-text-brand-color {
    font-weight: 300;
}