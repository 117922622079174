.dss-alert {
  padding: 8px 35px 8px 14px;
  margin-bottom: 22px;
  border-radius: 4px;
  border: none;
}
.dss-alert.dss-alert-warning {
  color: #806800;
  background-color: #f7f2cd;
}
.dss-alert.dss-alert-info {
  color: #004c73;
  background-color: #c5e4eb;
}
.dss-alert.dss-alert-error {
  color: #991200;
  background-color: #fdd6d1;
}
.dss-alert.dss-alert-success {
  color: #3a660a;
  background-color: #d3ebb9;
}
.dss-badge {
  background-color: #ddd;
  color: inherit;
  display: inline-block;
  padding: 2px 7px;
  border-radius: 8px;
  min-width: 10px;
  font-size: inherit;
  font-weight: normal;
  line-height: 1;
  vertical-align: baseline;
  text-align: center;
  white-space: nowrap;
  text-shadow: none;
}
.dss-badge:empty {
  display: none;
}
.dss-nav .active .dss-badge {
  background-color: #ffffff;
  color: #147db4;
}
.dss-btn,
input.dss-btn {
  background-color: #ffffff;
  color: #0f618c;
  border: solid 1px #0f618c;
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
  margin: 0;
  padding: 12px 30px;
  text-decoration: none;
  vertical-align: middle;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .dss-btn,
  input.dss-btn {
    width: 100%;
    margin-bottom: 5px;
  }
}
@media screen and (min-width: 768px) {
  .dss-btn,
  input.dss-btn {
    width: auto;
  }
}
.dss-btn:hover,
input.dss-btn:hover,
.dss-btn:focus,
input.dss-btn:focus {
  text-decoration: none;
  color: #0f618c;
}
.dss-btn.active,
input.dss-btn.active,
.dss-btn:active,
input.dss-btn:active {
  background-color: #0f618c;
  color: #ffffff;
  border-color: #0f618c;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.35);
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}
.dss-btn.disabled,
input.dss-btn.disabled,
.dss-btn[disabled],
input.dss-btn[disabled] {
  color: #000000;
  text-shadow: none;
  background-color: #e0e0e0;
  background-image: none;
  opacity: 0.6;
  cursor: default;
  box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.7);
  border-color: #b6b6b6;
}
.dss-btn.disabled:hover,
input.dss-btn.disabled:hover,
.dss-btn[disabled]:hover,
input.dss-btn[disabled]:hover,
.dss-btn.disabled:focus,
input.dss-btn.disabled:focus,
.dss-btn[disabled]:focus,
input.dss-btn[disabled]:focus {
  color: #000000 !important;
}
.dss-btn-primary,
input.dss-btn-primary {
  background-color: #147db4;
  color: #ffffff;
  border: solid 1px #147db4;
}
.dss-btn-primary:hover,
input.dss-btn-primary:hover,
.dss-btn-primary:focus,
input.dss-btn-primary:focus {
  color: #ffffff;
}
.dss-btn-primary:active,
input.dss-btn-primary:active {
  color: #ffffff;
}
.dss-btn-sm,
input.dss-btn-sm {
  font-size: 14px;
  padding: 6px 20px;
}
.dss-btn-wider,
input.dss-btn-wider {
  padding-left: 2em;
  padding-right: 2em;
}
.dss-btn-active,
.dss-btn-active:hover {
  background: #147db4;
  color: #ffffff;
  cursor: default;
}
.dss-btn-group {
  font-size: 0;
  display: table;
}
@media screen and (max-width: 991px) {
  .dss-btn-group {
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .dss-btn-group {
    width: auto;
  }
}
.dss-btn-group > .dss-btn {
  border-color: #979797;
  border-radius: 0;
  margin: 0;
  padding: 7px 8px;
  background: #eeefeb;
  display: table-cell;
}
.dss-btn-group > .dss-btn:active,
.dss-btn-group > .dss-btn:focus {
  color: #ffffff;
  background: #0f618c;
  border-color: #0f618c;
}
.dss-btn-group > .dss-btn.dss-btn-active {
  color: #ffffff;
  background: #147db4;
  border-color: #147db4;
}
.dss-btn-group > .dss-btn:first-child {
  border-radius: 4px 0 0 4px;
}
.dss-btn-group > .dss-btn:last-child {
  border-radius: 0 4px 4px 0;
}
.dss-btn-group > .dss-btn + .dss-btn {
  border-left: none;
}
.dss-btn-group.dss-btn-group-2 > .dss-btn {
  width: 50%;
}
.dss-btn-group.dss-btn-group-3 > .dss-btn {
  width: 33%;
}
.dss-btn-group.dss-btn-group-4 > .dss-btn {
  width: 25%;
}
.dss-navbar-btn-group-container {
  background: #147db4;
}
.dss-navbar-btn-group-container .dss-navbar-btn-group-container-inner {
  margin-left: 15px;
  margin-right: 15px;
}
.dss-navbar-btn-group {
  font-size: 0;
  width: 100%;
  display: table;
}
@media screen and (min-width: 992px) {
  .dss-navbar-btn-group {
    border-left: solid 1px #ddd;
    border-right: solid 1px #ddd;
  }
}
.dss-navbar-btn-group > .dss-btn {
  margin: 0;
  width: 50%;
  display: table-cell;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0;
}
@media screen and (max-width: 992px) {
  .dss-navbar-btn-group > .dss-btn {
    padding-left: 5px;
    padding-right: 5px;
  }
}
.dss-navbar-btn-group > .dss-btn:active,
.dss-navbar-btn-group > .dss-btn:focus {
  background: #0f618c;
}
.dss-navbar-btn-group > .dss-btn.dss-btn-active {
  background: #147db4;
}
.dss-navbar-btn-group > .dss-btn + .dss-btn {
  border-left: solid 1px #ddd;
}
/* Side notes for calling out things
-------------------------------------------------- */
/* Base styles (regardless of theme) */
.dss-callout {
  margin: 20px 0;
  padding: 15px 30px 15px 15px;
  border-left: 5px solid #eee;
}
.dss-callout h4 {
  margin-top: 0;
}
.dss-callout p:last-child {
  margin-bottom: 0;
}
.dss-callout code,
.dss-callout .highlight {
  background-color: #ffffff;
}
/* Themes for different contexts */
.dss-callout-info {
  background-color: #ebfbff;
  border-color: #98aab3;
  color: #004C73;
}
.dss-callout-info h2,
.dss-callout-info h3,
.dss-callout-info h4 {
  color: #004C73;
}
.dss-callout-warning {
  background-color: #fefbed;
  border-color: #ded29b;
  color: #806208;
}
.dss-callout-warning h2,
.dss-callout-warning h3,
.dss-callout-warning h4 {
  color: #806208;
}
.dss-callout-danger {
  background-color: #fcf2f2;
  border-color: #de9c9b;
  color: #800a08;
}
.dss-callout-danger h2,
.dss-callout-danger h3,
.dss-callout-danger h4 {
  color: #800a08;
}
.dss-card {
  background-color: #ffffff;
  margin-top: 22px;
  margin-bottom: 22px;
  min-height: 20px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 0;
  padding-bottom: 17px;
  overflow: hidden;
  border-left: solid 3px #147db4;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.15);
}
#global-footer-wrapper {
  min-height: 80px;
  background-color: #393c43;
  color: #ffffff;
}
#global-footer {
  padding-top: 12px;
  padding-bottom: 12px;
}
#global-footer a {
  color: #ffffff;
}
.dss-form {
  margin: 0;
  padding: 0;
}
.dss-form .dss-form-actions {
  margin-top: 2em;
  margin-bottom: 0;
  text-align: right;
}
.dss-form .dss-form-actions .dss-btn {
  margin-left: 1em;
}
.dss-form legend {
  box-shadow: 0 1px 0px 0 rgba(255, 255, 255, 0.4);
  border-color: #DFDFD7;
  font-size: 17.3px;
  line-height: 30px;
  margin-top: 5px;
  padding-top: 5px;
}
label {
  cursor: default;
}
label.radio,
label.checkbox {
  cursor: pointer;
}
label {
  font-weight: normal;
}
.container-fluid {
  max-width: 970px;
}
a:hover [class^="icon-"],
a:hover [class*=" icon-"] {
  text-decoration: none;
}
[class^="icon-"].icon-space-left,
[class*=" icon-"].icon-space-left {
  margin-left: 0.3em;
}
[class^="icon-"].icon-space-right,
[class*=" icon-"].icon-space-right {
  margin-right: 0.3em;
}
.dss-nav {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 22px;
  list-style: none;
}
.dss-nav a {
  color: #555555;
}
.dss-nav > li > a {
  display: block;
}
.dss-nav > li > a:hover {
  text-decoration: none;
  background-color: #eee;
}
.dss-nav > li > a:focus {
  text-decoration: none;
  color: #222222;
}
.dss-nav > li > a > img {
  max-width: none;
}
.dss-nav > .pull-right {
  float: right;
}
.dss-nav-list {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 0;
}
.dss-nav-list > li > a {
  margin-left: -15px;
  margin-right: -15px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  padding: 3px 15px;
}
.dss-nav-list > .active > a,
.dss-nav-list > .active > a:hover,
.dss-nav-list > .active > a:focus {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  background-color: #147db4;
}
.dss-nav-list [class^="icon-"],
.dss-nav-list [class*=" icon-"] {
  margin-right: 2px;
}
.dss-nav-tabs {
  font-size: 0;
  margin-top: 22px;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 22px;
  padding: 0;
  text-align: center;
  float: left;
  height: 100%;
}
@media screen and (max-width: 768px) {
  .dss-nav-tabs {
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .dss-nav-tabs {
    width: auto;
  }
}
.dss-nav-tabs > li {
  line-height: 22px;
  font-weight: normal;
  white-space: nowrap;
  margin: 0;
  display: inline-block;
  position: relative;
  background: white;
  color: #000000;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.3);
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .dss-nav-tabs > li {
    font-size: 14px;
    padding: 21px 0;
    width: 49.5%;
  }
}
@media screen and (min-width: 768px) {
  .dss-nav-tabs > li {
    font-size: 16px;
    padding: 27px 30px;
  }
}
.dss-nav-tabs > li:active {
  background: #0f618c;
  color: #ffffff;
}
.dss-nav-tabs > li.active {
  background: #147db4;
  color: #ffffff;
  cursor: default;
}
.dss-nav-tabs > li.active:after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-top-color: #147db4;
  border-width: 10px;
  margin-left: -10px;
}
.dss-nav-tabs > li:first-child {
  border-radius: 4px 0 0 4px;
}
.dss-nav-tabs > li:last-child {
  border-radius: 0 4px 4px 0;
}
.dss-panel {
  background-color: #ffffff;
  margin-top: 0;
  margin-bottom: 22px;
  min-height: 20px;
  padding: 40px;
  overflow: hidden;
}
.dss-panel.dss-panel-max-headroom {
  padding-top: 35px;
}
.dss-panel.dss-panel-haircut {
  padding-top: 10px;
}
.dss-panel.dss-panel-drop-shadow {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15);
}
@media screen and (max-width: 768px) {
  .dss-panel-test {
    background: #EBEBE7;
  }
}
@media screen and (min-width: 768px) {
  .dss-panel-test {
    background: #ffffff;
  }
}
@media screen and (max-width: 768px) {
  .dss-panel-test .dss-panel-test-inner {
    background: none;
  }
}
@media screen and (min-width: 768px) {
  .dss-panel-test .dss-panel-test-inner {
    background: #EBEBE7;
    border-radius: 2px;
    padding-left: 30px;
    padding-right: 30px;
  }
}
.dss-rhino {
  margin: 0;
  padding: 0;
}
.dss-rhino.dss-rhino-white {
  background-color: #ffffff;
}
.dss-rhino.dss-rhino-greige {
  background-color: #eeefeb;
  background-image: url('../img/bg_textures/greige_mesh_lighter.png');
  background-repeat: repeat;
}
.dss-rhino.dss-rhino-brand-color {
  background-color: #147db4;
}
.dss-rhino.dss-rhino-brand-color-darker {
  background-color: #0f618c;
}
.dss-rhino.dss-rhino-padding-top-5 {
  padding-top: 5px;
}
.dss-rhino.dss-rhino-padding-bottom-5 {
  padding-bottom: 5px;
}
.dss-rhino.dss-rhino-padding-top-10 {
  padding-top: 10px;
}
.dss-rhino.dss-rhino-padding-bottom-10 {
  padding-bottom: 10px;
}
.dss-rhino.dss-rhino-padding-top-15 {
  padding-top: 15px;
}
.dss-rhino.dss-rhino-padding-bottom-15 {
  padding-bottom: 15px;
}
.dss-rhino.dss-rhino-padding-top-20 {
  padding-top: 20px;
}
.dss-rhino.dss-rhino-padding-bottom-20 {
  padding-bottom: 20px;
}
.dss-rhino.dss-rhino-padding-top-25 {
  padding-top: 25px;
}
.dss-rhino.dss-rhino-padding-bottom-25 {
  padding-bottom: 25px;
}
.dss-rhino.dss-rhino-padding-top-30 {
  padding-top: 30px;
}
.dss-rhino.dss-rhino-padding-bottom-30 {
  padding-bottom: 30px;
}
.dss-rhino.dss-rhino-padding-top-35 {
  padding-top: 35px;
}
.dss-rhino.dss-rhino-padding-bottom-35 {
  padding-bottom: 35px;
}
.dss-rhino.dss-rhino-padding-top-40 {
  padding-top: 40px;
}
.dss-rhino.dss-rhino-padding-bottom-40 {
  padding-bottom: 40px;
}
html,
body {
  height: 100%;
}
body {
  color: #000000;
  background-color: #393c43;
  background-repeat: repeat;
  min-width: 100%;
}
#global-wrapper {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  margin: 0px;
  margin-bottom: -80px;
  background: #ffffff;
}
.page-content-wrapper {
  position: relative;
}
@media screen and (max-width: 768px) {
  .page-content-wrapper {
    padding-bottom: 90px;
  }
}
@media screen and (min-width: 768px) {
  .page-content-wrapper {
    padding-bottom: 100px;
  }
}
hr.dss-skip-small {
  margin-top: 16.5px;
  margin-bottom: 16.5px;
}
hr.dss-skip-big {
  margin-top: 33px;
  margin-bottom: 33px;
}
hr.dss-skip-giant {
  margin-top: 44px;
  margin-bottom: 44px;
}
span.dss-skip-small,
span.dss-skip-med,
span.dss-skip-big,
span.dss-skip-giant {
  display: block;
}
span.dss-skip-small {
  height: 16.5px;
}
span.dss-skip-med {
  height: 22px;
}
span.dss-skip-big {
  height: 33px;
}
span.dss-skip-giant {
  height: 44px;
}
.dss-table-container {
  padding: 0;
  overflow-x: auto;
  margin-top: 0;
  margin-bottom: 22px;
}
.dss-table-container.dss-table-container-bordered {
  border: solid 1px #ddd;
  border: solid 1px rgba(180, 180, 180, 0.3);
}
.dss-table-container.dss-table-container-rounded {
  border-radius: 4px;
}
.dss-table-container.dss-table-container-rounded table {
  border-radius: 4px;
}
.dss-table-container.dss-table-container-rounded thead:first-child tr:first-child > th:first-child,
.dss-table-container.dss-table-container-rounded tbody:first-child tr:first-child > td:first-child,
.dss-table-container.dss-table-container-rounded tbody:first-child tr:first-child > th:first-child {
  border-top-left-radius: 4px;
}
.dss-table-container.dss-table-container-rounded thead:first-child tr:first-child > th:last-child,
.dss-table-container.dss-table-container-rounded tbody:first-child tr:first-child > td:last-child,
.dss-table-container.dss-table-container-rounded tbody:first-child tr:first-child > th:last-child {
  border-top-right-radius: 4px;
}
.dss-table-container.dss-table-container-rounded thead:last-child tr:last-child > th:first-child,
.dss-table-container.dss-table-container-rounded tbody:last-child tr:last-child > td:first-child,
.dss-table-container.dss-table-container-rounded tbody:last-child tr:last-child > th:first-child,
.dss-table-container.dss-table-container-rounded tfoot:last-child tr:last-child > td:first-child,
.dss-table-container.dss-table-container-rounded tfoot:last-child tr:last-child > th:first-child {
  border-bottom-left-radius: 4px;
}
.dss-table-container.dss-table-container-rounded thead:last-child tr:last-child > th:last-child,
.dss-table-container.dss-table-container-rounded tbody:last-child tr:last-child > td:last-child,
.dss-table-container.dss-table-container-rounded tbody:last-child tr:last-child > th:last-child,
.dss-table-container.dss-table-container-rounded tfoot:last-child tr:last-child > td:last-child,
.dss-table-container.dss-table-container-rounded tfoot:last-child tr:last-child > th:last-child {
  border-bottom-right-radius: 4px;
}
.dss-table {
  width: 100%;
  background-color: transparent;
  border-collapse: separate;
  border-spacing: 0;
}
.dss-table thead th,
.dss-table thead td {
  color: #54636e;
  font-weight: bold;
  text-shadow: 0 1px #fff;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.dss-table tbody th,
.dss-table tbody td,
.dss-table tfoot th,
.dss-table tfoot td {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 6px;
  padding-bottom: 3px;
}
.dss-table th,
.dss-table td {
  line-height: 22px;
  text-align: left;
  vertical-align: top;
  border-bottom: solid 1px #ddd;
  border-bottom: solid 1px rgba(180, 180, 180, 0.3);
}
.dss-table tbody:last-child > tr:last-child > th,
.dss-table tbody:last-child > tr:last-child > td,
.dss-table tfoot:last-child > tr:last-child > td,
.dss-table tfoot:last-child > tr:last-child > th {
  border-bottom: none;
}
.dss-table-condensed thead th,
.dss-table-condensed thead td {
  padding: 4px 8px;
}
.dss-table-condensed tbody th,
.dss-table-condensed tbody td {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 5px;
  padding-bottom: 2px;
}
.dss-table-striped tbody > tr:nth-child(odd) > td,
.dss-table-striped tbody > tr:nth-child(odd) > th {
  background-color: rgba(76, 76, 76, 0.04);
  border-bottom-color: transparent;
}
.dss-table-striped tbody > tr:nth-child(even) > td,
.dss-table-striped tbody > tr:nth-child(even) > th {
  border-bottom-color: transparent;
}
.dss-table-hover tbody > tr:not(.no-hover):hover > td,
.dss-table-hover tbody > tr:not(.no-hover):hover > th {
  background-color: #147db4 !important;
  color: #ffffff;
}
.dss-table-hover tbody > tr:not(.no-hover):hover > td a,
.dss-table-hover tbody > tr:not(.no-hover):hover > th a,
.dss-table-hover tbody > tr:not(.no-hover):hover > td span,
.dss-table-hover tbody > tr:not(.no-hover):hover > th span {
  color: #ffffff;
}
th.number,
td.number,
th.currency,
td.currency,
td.date,
th.date {
  text-align: right;
  padding-right: 16px;
}
td.summary,
th.summary {
  font-weight: bold;
}
h1 {
  font-size: 28px;
  line-height: 38px;
}
h2 {
  font-size: 24px;
  line-height: 33px;
}
h3 {
  font-size: 21.3px;
  line-height: 29.2875px;
}
h4 {
  font-size: 18.7px;
  line-height: 25.7125px;
}
h5,
h6 {
  font-size: 16px;
  line-height: 22px;
}
h1,
h2,
h3,
h4 {
  font-weight: normal;
}
h1 small {
  font-size: 21.3px;
}
h2 small {
  font-size: 18.7px;
}
h3 small {
  font-size: 16px;
}
h4 small {
  font-size: 16px;
}
h1.dss-heading-lg {
  font-size: 48px;
  line-height: 66px;
}
h2.dss-heading-lg {
  font-size: 32px;
  line-height: 44px;
}
h3.dss-heading-lg {
  font-size: 28px;
  line-height: 38.5px;
}
.lead {
  font-size: 18.7px;
  line-height: 25px;
}
.lead.dss-lead-lg {
  font-size: 21.3px;
}
hr {
  margin-top: 22px;
  margin-bottom: 22px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}
.dss-text-brand-color,
div.dss-text-brand-color,
span.dss-text-brand-color {
  color: #ffffff;
}
.dss-text-brand-color h1,
div.dss-text-brand-color h1,
span.dss-text-brand-color h1,
.dss-text-brand-color h2,
div.dss-text-brand-color h2,
span.dss-text-brand-color h2,
.dss-text-brand-color h3,
div.dss-text-brand-color h3,
span.dss-text-brand-color h3,
.dss-text-brand-color h4,
div.dss-text-brand-color h4,
span.dss-text-brand-color h4,
.dss-text-brand-color p,
div.dss-text-brand-color p,
span.dss-text-brand-color p,
.dss-text-brand-color ul,
div.dss-text-brand-color ul,
span.dss-text-brand-color ul,
.dss-text-brand-color ol,
div.dss-text-brand-color ol,
span.dss-text-brand-color ol,
.dss-text-brand-color li,
div.dss-text-brand-color li,
span.dss-text-brand-color li,
.dss-text-brand-color a,
div.dss-text-brand-color a,
span.dss-text-brand-color a,
.dss-text-brand-color a:hover,
div.dss-text-brand-color a:hover,
span.dss-text-brand-color a:hover,
.dss-text-brand-color a:active,
div.dss-text-brand-color a:active,
span.dss-text-brand-color a:active,
.dss-text-brand-color a:visited,
div.dss-text-brand-color a:visited,
span.dss-text-brand-color a:visited {
  color: #ffffff;
}
.dss-text-brand-color a,
div.dss-text-brand-color a,
span.dss-text-brand-color a,
.dss-text-brand-color a:hover,
div.dss-text-brand-color a:hover,
span.dss-text-brand-color a:hover,
.dss-text-brand-color a:active,
div.dss-text-brand-color a:active,
span.dss-text-brand-color a:active,
.dss-text-brand-color a:visited,
div.dss-text-brand-color a:visited,
span.dss-text-brand-color a:visited {
  text-decoration: underline;
}
a.muted:hover,
a.muted:focus {
  color: #006aff;
}
.text-danger {
  color: #7d1b13;
}
a.text-danger:hover,
a.text-danger:focus {
  color: #a9251a;
}
.dss-todo {
  color: #990000;
  background-color: #ffffbb;
}
.dss-zap-padding-left {
  padding-left: 0 !important;
}
.dss-zap-padding-top {
  padding-top: 0 !important;
}
.dss-zap-padding-right {
  padding-right: 0 !important;
}
.dss-zap-padding-bottom {
  padding-bottom: 0 !important;
}
.dss-zap-padding-all {
  padding: 0 !important;
}
.dss-pull-up1 {
  margin-top: -5px;
}
.dss-pull-up2 {
  margin-top: -10px;
}
.dss-pull-up3 {
  margin-top: -15px;
}
.dss-pull-up4 {
  margin-top: -20px;
}
.dss-pull-up5 {
  margin-top: -25px;
}
.dss-pull-up6 {
  margin-top: -30px;
}
.dss-pull-up7 {
  margin-top: -35px;
}
.dss-pull-up8 {
  margin-top: -40px;
}
.dss-pull-up9 {
  margin-top: -45px;
}
.dss-pull-up10 {
  margin-top: -50px;
}
.dss-pull-up11 {
  margin-top: -55px;
}
.dss-pull-up12 {
  margin-top: -60px;
}
.dss-pull-down1 {
  margin-top: 5px;
}
.dss-pull-down2 {
  margin-top: 10px;
}
.dss-pull-down3 {
  margin-top: 15px;
}
.dss-pull-down4 {
  margin-top: 20px;
}
.dss-pull-down5 {
  margin-top: 25px;
}
.dss-pull-down6 {
  margin-top: 30px;
}
.dss-pull-down7 {
  margin-top: 35px;
}
.dss-pull-down8 {
  margin-top: 40px;
}
.dss-pull-down9 {
  margin-top: 45px;
}
.dss-pull-down10 {
  margin-top: 50px;
}
.dss-pull-down11 {
  margin-top: 55px;
}
.dss-pull-down12 {
  margin-top: 60px;
}
/* dedicated class for examples in docs - cloned from Bootstrap docs.css */
.dss-docs-example {
  position: relative;
  padding: 45px 15px 15px;
  margin: 15px 0;
  border-color: #e5e5e5 #eee #eee;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
}
/* Echo out a label for the example */
.dss-docs-example:after {
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 12px;
  font-weight: bold;
  color: #959595;
  text-transform: uppercase;
  letter-spacing: 1px;
  content: "Example";
}
