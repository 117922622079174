@font-face {
	font-family: 'icomoon';
	src:url('fonts/icomoon.eot?lbe0v6');
	src:url('fonts/icomoon.eot?#iefixlbe0v6') format('embedded-opentype'),
		url('fonts/icomoon.woff?lbe0v6') format('woff'),
		url('fonts/icomoon.ttf?lbe0v6') format('truetype'),
		url('fonts/icomoon.svg?lbe0v6#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icomoon-"], [class*=" icomoon-"] {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icomoon-delib:before {
	content: "\e600";
}

